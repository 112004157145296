import { defineMessages } from 'react-intl';

export default defineMessages({
    addCondition: {
        id: 'AttributeFilter.addCondition',
        defaultMessage: 'Добавить условие',
    },
    attribute: {
        id: 'AttributeFilter.attribute',
        defaultMessage: 'Атрибут',
    },
    condition: {
        id: 'AttributeFilter.condition',
        defaultMessage: 'Условие',
    },
    value: {
        id: 'AttributeFilter.value',
        defaultMessage: 'Значение',
    },
    methodologyAttributes: {
        id: 'AttributeFilter.methodologyAttributes',
        defaultMessage: 'Атрибуты методологии',
    },
    HAS_VALUE: {
        id: 'AttributeFilter.HAS_VALUE',
        defaultMessage: 'Заполнен',
    },
    HAS_NOT_VALUE: {
        id: 'AttributeFilter.HAS_NOT_VALUE',
        defaultMessage: 'Не заполнен',
    },
    HAS_NOT_VALUE_OR_ABSENT: {
        id: 'AttributeFilter.HAS_NOT_VALUE_OR_ABSENT',
        defaultMessage: 'Не заполнен или отсутствует',
    },
    IS_ABSENT: {
        id: 'AttributeFilter.IS_ABSENT',
        defaultMessage: 'Отсутствует',
    },
    EQUALS: {
        id: 'AttributeFilter.EQUALS',
        defaultMessage: 'Равно',
    },
    NOT_EQUALS: {
        id: 'AttributeFilter.NOT_EQUALS',
        defaultMessage: 'Не равно',
    },
    GREATER: {
        id: 'AttributeFilter.GREATER',
        defaultMessage: 'Больше',
    },
    GREATER_OR_EQUAL: {
        id: 'AttributeFilter.GREATER_OR_EQUAL',
        defaultMessage: 'Больше или равно',
    },
    LESS: {
        id: 'AttributeFilter.LESS',
        defaultMessage: 'Меньше',
    },
    LESS_OR_EQUAL: {
        id: 'AttributeFilter.LESS_OR_EQUAL',
        defaultMessage: 'Меньше или равно',
    },
    CONTAINS: {
        id: 'AttributeFilter.CONTAINS',
        defaultMessage: 'Содержит',
    },
    NOT_CONTAINS: {
        id: 'AttributeFilter.NOT_CONTAINS',
        defaultMessage: 'Не содержит',
    },
    STARTS_WITH: {
        id: 'AttributeFilter.STARTS_WITH',
        defaultMessage: 'Текст начинается с',
    },
    ENDS_WITH: {
        id: 'AttributeFilter.ENDS_WITH',
        defaultMessage: 'Текст заканчивается на',
    },
    NOT_STARTS_WITH: {
        id: 'AttributeFilter.NOT_STARTS_WITH',
        defaultMessage: 'Текст не начинается с',
    },
    NOT_ENDS_WITH: {
        id: 'AttributeFilter.NOT_ENDS_WITH',
        defaultMessage: 'Текст не заканчивается на',
    },
    Name: {
        id: 'AttributeFilter.Name',
        defaultMessage: 'Наименование',
    },
    ModelType: {
        id: 'AttributeFilter.ModelType',
        defaultMessage: 'Тип модели',
    },
    ObjectType: {
        id: 'AttributeFilter.ObjectType',
        defaultMessage: 'Тип объекта',
    },
    EdgeType: {
        id: 'AttributeFilter.EdgeType',
        defaultMessage: 'Тип связи',
    },
    CreatedAt: {
        id: 'AttributeFilter.CreatedAt',
        defaultMessage: 'Дата создания',
    },
    CreatedBy: {
        id: 'AttributeFilter.CreatedBy',
        defaultMessage: 'Создал',
    },
    UpdatedAt: {
        id: 'AttributeFilter.UpdatedAt',
        defaultMessage: 'Дата изменения',
    },
    UpdatedBy: {
        id: 'AttributeFilter.UpdatedBy',
        defaultMessage: 'Изменил',
    },
    DeletedBy: {
        id: 'AttributeFilter.DeletedBy',
        defaultMessage: 'Удалил',
    },
    DeletedAt: {
        id: 'AttributeFilter.DeletedAt',
        defaultMessage: 'Дата удаления',
    },
    FolderType: {
        id: 'AttributeFilter.FolderType',
        defaultMessage: 'Тип папки',
    },
    SymbolType: {
        id: 'AttributeFilter.SymbolType',
        defaultMessage: 'Тип символа',
    },
    UserEditDisabled: {
        id: 'AttributeFilter.UserEditDisabled',
        defaultMessage: 'Запрещено редактирование пользователю',
    },
    ScriptEngineEditDisabled: {
        id: 'AttributeFilter.ScriptEngineEditDisabled',
        defaultMessage: 'Запрещено редактирование скрипту',
    },
    Confidential: {
        id: 'AttributeFilter.Confidential',
        defaultMessage: 'Конфиденциально',
    },
    systemAttributes: {
        id: 'AttributeFilter.systemAttributes',
        defaultMessage: 'Системные атрибуты',
    },
});
