import {
    AttributeType,
    AttributeTypeValueTypeEnum,
    AttributeValuePrincipal,
    NodeId,
    SearchRuleQueryRuleEnum,
} from '@/serverapi/api';
import React, { FC, useEffect, useState } from 'react';
import theme from './AttributeFilter.scss';
import { v4 as uuid } from 'uuid';
import { Checkbox, DatePicker, Input, Select, TimePicker } from 'antd';
import { AttributeValueType } from '@/modules/FloatingAttributes/components/AttributesEditor/Attribute.types';
import { LocalesService } from '@/services/LocalesService';
import { dateFormat, dateTimeFormat, timestampToMomentDate } from '@/utils/date.time.utils';
import icEdit from '@/resources/icons/edit.svg';
import dayjs from 'dayjs';
import { getAntdPopupContainer } from '@/utils/getAntdPopupContainer';
import { Locale } from '@/modules/Header/components/Header/header.types';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { TreeNode } from '@/models/tree.types';
import { getNodeName } from '@/modules/Navigator/navigatorUtils/navigatorTreeSearch.utils';
import { PrincipalsSelectors } from '@/selectors/principals.selectors';
import {
    formatUserName,
    getGroupsSelectedData,
    getUsersSelectedData,
} from '@/modules/ObjectPropertiesDialog/components/utils';
import UserLoginComponent from '@/modules/ObjectPropertiesDialog/components/PrincipalAttributeType/UserLogin.component';
import GroupNameComponent from '@/modules/ObjectPropertiesDialog/components/PrincipalAttributeType/GroupName.component';
import { TSystemAttributeEntityOptions } from './AttributeFilter.types';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { DatePickerProvider } from '@/modules/UIKit/H.O.C/DatePickerProvider/DatePickerProvider.hoc';

type TAttributeValueInputProps = {
    ruleId: string;
    attributeType: AttributeType | undefined;
    queryRule: SearchRuleQueryRuleEnum;
    values: { id: string; value: string }[];
    onAttributeValueChange: (ruleId: string, values: { id: string; value: string }[]) => void;
    nodeId: NodeId;
    systemAttributeEntityOptions: TSystemAttributeEntityOptions[] | undefined;
    id: string;
    dataTest?: string;
};

export const AttributeValueInput: FC<TAttributeValueInputProps> = (props) => {
    const {
        ruleId,
        attributeType,
        queryRule,
        values,
        onAttributeValueChange,
        nodeId,
        systemAttributeEntityOptions,
        id,
    } = props;
    const currentLocale = useSelector(getCurrentLocale);
    const principals = useSelector(PrincipalsSelectors.getAll);
    const dispatch = useDispatch();
    const [nodeAttrName, setNodeAttrName] = useState<string>('');
    const [principalAttrValue, setPrincipalAttrValue] = useState<AttributeValuePrincipal>({
        id: uuid(),
        value: '',
        logins: [],
        groupIds: [],
        typeId: attributeType?.id,
        valueType: attributeType?.valueType,
    } as AttributeValuePrincipal);

    useEffect(() => {
        if (nodeAttrName !== '' && attributeType?.valueType === AttributeValueType.NODE) setNodeAttrName('');
        if (
            (principalAttrValue.logins || principalAttrValue.groupIds) &&
            attributeType?.valueType === AttributeValueType.PRINCIPAL &&
            ['HAS_VALUE', 'HAS_NOT_VALUE', 'HAS_NOT_VALUE_OR_ABSENT', 'IS_ABSENT'].includes(queryRule)
        )
            setPrincipalAttrValue({ ...principalAttrValue, logins: [], groupIds: [] });
    }, [queryRule]);

    if (
        ['HAS_VALUE', 'HAS_NOT_VALUE', 'HAS_NOT_VALUE_OR_ABSENT', 'IS_ABSENT'].includes(queryRule) ||
        !attributeType?.valueType
    ) {
        return <Input id={ruleId} disabled={true} value={''} />;
    }

    const isText: boolean = (
        [
            AttributeValueType.STRING,
            AttributeValueType.MULTI_STRING,
            AttributeValueType.JSON,
            AttributeValueType.URL,
        ] as AttributeTypeValueTypeEnum[]
    ).includes(attributeType.valueType);
    const isNumber: boolean = (
        [AttributeValueType.NUMERIC, AttributeValueType.INTEGER] as AttributeTypeValueTypeEnum[]
    ).includes(attributeType.valueType);
    const isBoolean: boolean = attributeType.valueType === AttributeValueType.BOOLEAN;
    const isDateOrDatetime: boolean = (
        [AttributeValueType.DATE, AttributeValueType.DATE_TIME] as AttributeTypeValueTypeEnum[]
    ).includes(attributeType.valueType);
    const isTime: boolean = attributeType.valueType === AttributeValueType.TIME;
    const isSelectOrMultiselect: boolean = (
        [AttributeValueType.SELECT, AttributeValueType.MULTI_SELECT] as AttributeTypeValueTypeEnum[]
    ).includes(attributeType.valueType);
    const isNode: boolean = attributeType.valueType === AttributeValueType.NODE;
    const isUser: boolean = attributeType.valueType === AttributeValueType.PRINCIPAL;

    const currentValue: string = values.find((val) => val.id === id)?.value || '';

    const onValueChange = (value: string) => {
        const newValues = [...values].map((v) => {
            if (v.id === id) return { id, value };
            return v;
        });
        onAttributeValueChange(ruleId, newValues);
    };

    if (systemAttributeEntityOptions) {
        return (
            <Select
                style={{ width: '100%' }}
                defaultValue={currentValue}
                onChange={onValueChange}
                allowClear={false}
                getPopupContainer={getAntdPopupContainer}
                virtual={false}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                    return ((option?.children ?? '') as string)
                        .toLowerCase()
                        .includes(input.toLocaleLowerCase().trim());
                }}
                data-test={props.dataTest}
            >
                {systemAttributeEntityOptions.map((v) => {
                    return (
                        <Select.Option key={v.id} value={v.id}>
                            {v.name}
                        </Select.Option>
                    );
                })}
            </Select>
        );
    }

    return (
        <>
            {isText && (
                <Input
                    id={ruleId}
                    onChange={(event) => {
                        onValueChange(event.currentTarget.value);
                    }}
                    value={currentValue}
                    data-test={props.dataTest}
                />
            )}
            {isNumber && (
                <Input
                    id={ruleId}
                    type="number"
                    onChange={(event) => {
                        onValueChange(event.currentTarget.value);
                    }}
                    value={currentValue}
                    data-test={props.dataTest}
                />
            )}
            {isBoolean && (
                <Checkbox
                    checked={currentValue === 'true'}
                    onChange={(event) => {
                        onValueChange(`${event.target.checked}`);
                    }}
                    data-test={props.dataTest}
                />
            )}
            {isDateOrDatetime && (
                <DatePickerProvider>
                    <DatePicker
                        style={{ width: '100%' }}
                        showTime={attributeType.valueType === AttributeValueType.DATE_TIME}
                        format={attributeType.valueType === AttributeValueType.DATE_TIME ? dateTimeFormat : dateFormat}
                        defaultValue={currentValue ? timestampToMomentDate(+currentValue) : undefined}
                        onChange={(date: dayjs.Dayjs) => {
                            const value = date?.valueOf().toString() ?? '';
                            onValueChange(value);
                        }}
                        data-test={props.dataTest}
                    />
                </DatePickerProvider>
            )}
            {isTime && (
                <DatePickerProvider>
                    <TimePicker
                        style={{ width: '100%' }}
                        defaultValue={currentValue ? timestampToMomentDate(+currentValue) : undefined}
                        onChange={(date: dayjs.Dayjs) => {
                            const value = date?.valueOf().toString() ?? '';
                            onValueChange(value);
                        }}
                        data-test={props.dataTest}
                    />
                </DatePickerProvider>
            )}
            {isSelectOrMultiselect && (
                <Select
                    style={{ width: '100%' }}
                    defaultValue={
                        attributeType.valueType === AttributeValueType.SELECT
                            ? currentValue
                            : values.filter((v) => v.value !== '').map((v) => v.value)
                    }
                    onChange={(value: string | string[]) => {
                        const newValue = typeof value === 'string' ? [value] : value;
                        const isMultiSelect = attributeType.valueType === AttributeValueType.MULTI_SELECT;
                        if (isMultiSelect) {
                            onAttributeValueChange(
                                ruleId,
                                newValue.map((v) => ({ value: v, id: uuid() })),
                            );
                        } else {
                            onValueChange(newValue[0]);
                        }
                    }}
                    allowClear={false}
                    getPopupContainer={getAntdPopupContainer}
                    mode={attributeType.valueType === AttributeValueType.MULTI_SELECT ? 'multiple' : undefined}
                    virtual={false}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                        return ((option?.children ?? '') as string)
                            .toLowerCase()
                            .includes(input.toLocaleLowerCase().trim());
                    }}
                    data-test={props.dataTest}
                >
                    {attributeType?.selectPropertyValues?.map((v) => {
                        return (
                            <Select.Option key={v.id} value={v.id}>
                                {LocalesService.internationalStringToString(v.value, currentLocale as Locale)}
                            </Select.Option>
                        );
                    })}
                </Select>
            )}
            {isNode && (
                <div className={theme.flexCenter} data-test={props.dataTest}>
                    <Button
                        onClick={() => {
                            dispatch(
                                openDialog(DialogType.TREE_ITEM_SELECT_DIALOG, {
                                    serverId: nodeId.serverId,
                                    repositoryId: nodeId.repositoryId,
                                    disableContextMenu: true,
                                    isTreeWithClearButton: true,
                                    onSubmit: (nodeId: NodeId, node: TreeNode) => {
                                        setNodeAttrName(getNodeName(node));
                                        onValueChange(nodeId.id);
                                    },
                                    onClear: () => {
                                        setNodeAttrName('');
                                        onValueChange('');
                                    },
                                }),
                            );
                        }}
                        icon={icEdit}
                    />
                    <div className={theme.textContainer}>{nodeAttrName}</div>
                </div>
            )}
            {isUser && (
                <div className={theme.flexCenter} data-test={props.dataTest}>
                    <Button
                        onClick={() => {
                            dispatch(
                                openDialog(DialogType.PRICIPAL_ATTRIBUTE_DIALOG, {
                                    serverId: nodeId.serverId,
                                    attributeName: attributeType?.name,
                                    attributeValue: principalAttrValue,
                                    onSubmit: (value: AttributeValuePrincipal) => {
                                        const { allSelectedUsers } = getUsersSelectedData(principals, value.logins);
                                        const { allSelectedGroups } = getGroupsSelectedData(principals, value.groupIds);
                                        const allValues = [
                                            ...allSelectedUsers.map((item) => `${item.login}`),
                                            ...allSelectedGroups.map((item) => `${item.id}`),
                                        ];
                                        onAttributeValueChange(
                                            ruleId,
                                            allValues.map((v) => ({ value: v, id: uuid() })),
                                        );
                                        setPrincipalAttrValue(value);
                                    },
                                }),
                            );
                        }}
                        icon={icEdit}
                    />
                    <div className={theme.textContainer}>
                        {getUsersSelectedData(principals, principalAttrValue.logins).allSelectedUsers?.map((user) => (
                            <UserLoginComponent
                                text={formatUserName(attributeType?.principalAttributeTypeSettings?.format)(user)}
                                user={user}
                                key={`principal-user_${user.login}`}
                            />
                        ))}
                        {getGroupsSelectedData(principals, principalAttrValue.groupIds).allSelectedGroups?.map(
                            (group) => (
                                <GroupNameComponent
                                    text={group.login}
                                    group={group}
                                    key={`principal-group_${group.id}`}
                                />
                            ),
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
